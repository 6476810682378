import React, { useState, useEffect } from 'react';
import '../styles/ExamPage.css';

const ExamPage = () => {
    const [timer, setTimer] = useState(7200); // 2-hour timer (7200 seconds)
    const [answers, setAnswers] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const questions = [
        {
            id: 1,
            question: "What is the capital of France?",
            options: ["Berlin", "Madrid", "Paris", "Rome"],
            correctAnswer: "Paris"
        },
        {
            id: 2,
            question: "Which is the largest planet in the solar system?",
            options: ["Earth", "Jupiter", "Mars", "Venus"],
            correctAnswer: "Jupiter"
        },
        {
            id: 3,
            question: "Who wrote 'Romeo and Juliet'?",
            options: ["William Shakespeare", "Charles Dickens", "Leo Tolstoy", "Jane Austen"],
            correctAnswer: "William Shakespeare"
        }
    ];

    useEffect(() => {
        const countdown = setInterval(() => {
            setTimer(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000);
        return () => clearInterval(countdown); // Cleanup interval on component unmount
    }, []);

    const handleOptionChange = (questionId, selectedOption) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: selectedOption
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        console.log('Submitted answers:', answers);
    };

    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <div className="exam-container">
            <header className="exam-header">
                <h1>ExamPro</h1>
                <div className="timer">Time Remaining: {formatTime(timer)}</div>
            </header>

            <form className="exam-form" onSubmit={handleSubmit}>
                {questions.map((question, index) => (
                    <div className="question" key={question.id}>
                        <h2>{index + 1}. {question.question}</h2>
                        <div className="options">
                            {question.options.map((option, idx) => (
                                <label key={idx} className="option-label">
                                    <input
                                        type="radio"
                                        name={`question-${question.id}`}
                                        value={option}
                                        checked={answers[question.id] === option}
                                        onChange={() => handleOptionChange(question.id, option)}
                                        disabled={submitted}
                                    />
                                    <span className="radio-custom"></span>
                                    {option}
                                </label>
                            ))}
                        </div>
                    </div>
                ))}

                {!submitted ? (
                    <button type="submit" className="submit-button">Submit Answers</button>
                ) : (
                    <div className="submitted-message">
                        Your answers have been submitted. Thank you!
                    </div>
                )}
            </form>
        </div>
    );
};

export default ExamPage;
