import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/Dashboard.css';

const DashboardPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { uuid } = location.state || {};

    const handleExamSelect = (exam) => {
        navigate(`/exampage`, { state: { uuid } });
    };

    const handleSignOut = () => {
        localStorage.removeItem('user');
        navigate('/signin', { replace: true });
    };

    useEffect(() => {
        // Push a new state to the browser history to replace the current one
        window.history.pushState(null, '', window.location.pathname);

        const handlePopState = () => {
            // When the user presses the back button, log them out and redirect to sign-in
            localStorage.removeItem('user');
            navigate('/signin', { replace: true });
        };

        // Listen for the popstate event when the back button is pressed
        window.addEventListener('popstate', handlePopState);

        return () => {
            // Clean up the event listener
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    return (
        <div className="dashboard-page">
            <header className="dashboard-header">
                <button className="signout-button" onClick={handleSignOut}>
                    Sign Out
                </button>
                <h1>Choose Your Exam</h1>
                <p>Welcome! Your ID: {uuid}</p>
            </header>
            <div className="exam-selection">
                <button className="exam-button" onClick={() => handleExamSelect('math')}>
                    Take Math Exam
                </button>
                <button className="cta-button" onClick={() => handleExamSelect('science')}>
                    Take Science Exam
                </button>
                <button className="exam-button" onClick={() => handleExamSelect('english')}>
                    Take English Exam
                </button>
            </div>
        </div>
    );
};

export default DashboardPage;
