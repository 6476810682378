import { API_SIGNUP_URL } from "./apiEndpoints";

export const registerUser = async (formData) => {
    try {
        const response = await fetch(API_SIGNUP_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json", // Set content type to JSON
                "Accept": "application/json",
            },
            body: JSON.stringify(formData), // Convert formData to JSON string
        });

        // Check if the response is OK (status code 200-299)
        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData.message || "Failed to submit the form";
            throw new Error(errorMessage); // Throw the error with the backend's message
        }

        const result = await response.json();
        return result; // Return the successful response data
    } catch (error) {
        throw error; // Rethrow error for handling in the calling component
    }
};
