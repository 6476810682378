import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, Link } from "react-router-dom";
import { registerUser } from "../service/Signup.js"; // Ensure this function is correctly implemented
import "../styles/Register.css";

const RegisterPage = () => {
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [dob, setDob] = useState("");
    const [address, setAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const navigate = useNavigate();

    const validatePassword = (password) => {
        const minLength = 8;
        const containsLetters = /[a-zA-Z]/.test(password);
        const containsNumbers = /\d/.test(password);

        if (password.length < minLength) {
            return "Password must be at least 8 characters long.";
        } else if (!containsLetters || !containsNumbers) {
            return "Password must contain both letters and numbers.";
        } else {
            return "";
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const passwordValidationError = validatePassword(password);
        if (passwordValidationError) {
            setPasswordError(passwordValidationError);
            return;
        } else {
            setPasswordError("");
        }

        if (
            !firstName || !lastName || !email || !phoneNumber || !dob ||
            !address || !gender || !password || !confirmPassword
        ) {
            setShowModal(true);
            setModalMessage("Please fill in all required fields! 😢");
            return;
        }

        if (password !== confirmPassword) {
            setShowModal(true);
            setModalMessage("Passwords do not match! 😢");
            return;
        }

        setIsLoading(true);

        try {
            const formData = {
                first_name: firstName,
                middle_name: middleName,
                last_name: lastName,
                email_address: email,
                date_of_birth: dob,
                address: address,
                phone_number: phoneNumber,
                gender: gender,
                password: password
            };

            // Log the data being sent to the backend
            console.log("Sending data to backend:", formData);

            // Send form data to the backend using the API call with the correct headers
            const response = await registerUser(formData);

            // Store the email in local storage after registration
            localStorage.setItem("email", email);

            // Assuming the response from the backend indicates success
            setModalMessage("Registered successfully!");
            setShowModal(true);

            // Redirect to the Signin page after 2 seconds
            setTimeout(() => {
                navigate("/signin");
            }, 2000);
        } catch (error) {
            setModalMessage(`Error Registering: ${error.message}`);
            setShowModal(true);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <section className="register-page">
            <div className="register-container">
                <h1 className="register-title">Register</h1>
                <form onSubmit={handleSubmit}>
                    {/* Form Fields */}
                    <label htmlFor="firstName" className="select-label">First Name*</label>
                    <input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        className="input-field"
                    />
                    <label htmlFor="middleName" className="select-label">Middle Name</label>
                    <input
                        type="text"
                        name="middleName"
                        placeholder="Middle Name"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                        className="input-field"
                    />
                    <label htmlFor="lastName" className="select-label">Last Name*</label>
                    <input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        className="input-field"
                    />
                    <label htmlFor="email" className="select-label">Email*</label>
                    <input
                        type="email"
                        name="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="input-field"
                    />
                    <label htmlFor="dob" className="select-label">Date of Birth*</label>
                    <input
                        type="date"
                        name="dob"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        required
                        className="input-field"
                    />
                    <label htmlFor="address" className="select-label">Address*</label>
                    <input
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        required
                        className="input-field"
                    />
                    <label htmlFor="phoneNumber" className="select-label">Phone Number*</label>
                    <input
                        type="tel"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                        className="input-field"
                    />
                    <label htmlFor="gender" className="select-label">Gender*</label>
                    <select
                        name="Gender"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                        required
                        className="input-field select-field"
                    >
                        <option value="">Select Gender</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>

                    {/* Password */}
                    <label htmlFor="password" className="select-label">Password*</label>
                    <div className="password-input-container">
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            className="input-field"
                        />
                        <span
                            className="password-toggle-icon"
                            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                        >
                            {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>
                    {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}

                    {/* Confirm Password */}
                    <label htmlFor="confirmPassword" className="select-label">Confirm Password*</label>
                    <div className="password-input-container">
                        <input
                            type={isConfirmPasswordVisible ? "text" : "password"}
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            className="input-field"
                        />
                        <span
                            className="password-toggle-icon"
                            onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                        >
                            {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>

                    <button type="submit" className="cta-button" disabled={isLoading}>
                        {isLoading ? <ClipLoader size={20} color={"#fff"} /> : "Register"}
                    </button>
                </form>

                {/* Modal for success/error messages */}
                {showModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <p>{modalMessage}</p>
                            <button onClick={() => setShowModal(false)} className="modal-close-btn">Close</button>
                        </div>
                    </div>
                )}

                <div className="signin-redirect">
                    <p>Already have an account? <Link to="/signin">Sign in here</Link></p>
                </div>
            </div>
        </section>
    );
};

export default RegisterPage;
