import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RegisterPage from '../components/Register.jsx';
import SigninPage from '../components/SigninPage.jsx';
import DashboardPage from '../components/Dashboard.jsx';
import ExamPage from '../components/ExamPage.jsx';

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<SigninPage />} />
                {/* <Route path="/" element={<LandingPage />} /> */}
                <Route path="/signin" element={<SigninPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/exampage" element={<ExamPage />} />

            </Routes>
        </Router>
    );
};

export default AppRoutes;
